
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import headers from './headers';
import { namespace } from 'vuex-class';
import { Inventory } from '@/interfaces/models/Inventory';

const inventory = namespace('inventory');

@Component({
  components: { VTable },
})
export default class InventoryTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: any[];
  @Prop({ type: Number, required: true }) public total!: number;

  @inventory.Mutation('setActive') public setActive!: any;

  public headers: ColumnHeader[] = headers;
  public async edit(item: Inventory) {
    await this.setActive(item);
    this.$router.push({ name: 'inventory.edit', params: { id: item._id } });
  }
}
