export default [
  {
    text: 'provision.table.method',
    value: 'method',
  },
  {
    text: 'provision.table.sum',
    value: 'sum',
    type: 'decimal',
  },
  {
    text: 'provision.table.status',
    value: 'status',
  },
  {
    text: 'provision.table.createdAt',
    type: 'date',
    value: 'createdAt',
    format: 'DD.MM.YYYY HH:mm',
  },
  {
    text: 'provision.table.provisionClaimedAt',
    value: 'provisionClaimedAt',
    type: 'date',
    format: 'DD.MM.YYYY HH:mm',
  },
  {
    text: 'provision.table.provision',
    value: 'provision',
    type: 'slot',
  },
];
