
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { PaymentTotalSum } from '@/interfaces/api/PaymentTotalSum';
import Notification from '@/mixins/Notification';
import TableTable from '@/components/table/TableTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import Filter from '@/interfaces/api/Filter';
import { Range } from '@/interfaces/components/Range';
import PaymentTable from '../../components/provision/PaymentTable.vue';
import { Payment } from '@/interfaces/models/Payment';
import ClaimProvisionForm from '@/components/provision/ClaimProvisionForm.vue';
import StackedForm from '@/mixins/StackedForm';
import ProvisionApiService from '@/api/http/ProvisionApiService';
import VDateRangePicker from '@/components/shared/form/VDateRangePicker.vue';
import moment from 'moment';
import { AxiosResponse, AxiosError } from 'axios';

const venue = namespace('venue');
const provision = namespace('provision');
const app = namespace('app');

@Component({
  components: { VDateRangePicker, ClaimProvisionForm, PaymentTable, VEmptyState, TableTable, VWrapper },
})
export default class Provision extends mixins(Notification, StackedForm) {
  @app.State('venues') public venues!: Venue[];

  @provision.State('items') public payments!: Payment[];
  @provision.State((state) => state.pagination.total) public total!: number;
  @provision.Action('fetch') public getPayments!: any;
  @provision.Action('setFilter') public setFilter!: (filter: Filter) => void;

  public selectedVenue: string = '';
  public range: { from: string; to: string } = { from: '', to: '' };
  public paymentTotalSum: string | null = null;

  public $refs!: {
    form: InstanceType<typeof ClaimProvisionForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  get tablePayments() {
    return this.payments.map((payment: Payment) => {
      return {
        ...payment,
        provisionClaimedAt: payment.provision.claimedAt,
      };
    });
  }

  public claimProvision() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        const api: ProvisionApiService = new ProvisionApiService();
        this.$startLoading('provision.claim');

        const data: { venue: string; month: string } = this.$refs.form.getData();
        api
          .claimProvision({ venue: data.venue, month: data.month })
          .then((response: AxiosResponse) => {
            this.notifySuccess('provision.notification.claiming');
          })
          .catch((error: AxiosError) => {
            this.notifyError(`notification.400`);
          })
          .finally(() => {
            this.$stopLoading('provision.claim');
          });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  @Watch('selectedVenue')
  public onVenueChange() {
    if (this.selectedVenue && this.selectedVenue.length > 0 && this.range.from.length > 0 && this.range.to.length > 0) {
      this.$startLoading('provision');
      this.setFilter({ venue: this.selectedVenue, ...this.range });
      this.getPayments({ page: 1 });
      this.$stopLoading('provision');
    }
  }

  @Watch('range')
  public onRangeChange() {
    if (this.range.from && this.range.to && this.selectedVenue && this.selectedVenue.length > 0) {
      this.$startLoading('provision');
      const rangeFilter: Filter = { venue: this.selectedVenue, ...this.range };
      this.setFilter(rangeFilter);
      this.getPayments({ page: 1 });

      const api: ProvisionApiService = new ProvisionApiService();
      api
        .totalSum(rangeFilter)
        .then((response: AxiosResponse<PaymentTotalSum>) => {
          this.paymentTotalSum = (response.data && response.data.totalSum) || null;
        })
        .finally(() => {
          this.$stopLoading('provision');
        });
    }
  }
}
