
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { Venue } from '../../interfaces/models/Venue';
import { InputType } from '../../enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class ClaimProvisionForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  get items() {
    return [
      {
        name: 'venue',
        label: 'provision.form.venue',
        type: InputType.Autocomplete,
        itemValue: '_id',
        itemText: 'name',
        rules: 'required',
        items: this.venues,
      },
      { name: 'month', label: 'provision.form.venue', type: InputType.DateMonth, rules: 'required' },
    ];
  }
}
