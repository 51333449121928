
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import { Article } from '@/interfaces/models/Article';
import { StockManagementType } from '@/enums/StockManagementType';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { Category } from '@/interfaces/models/Category';
import { Permission } from '@/enums/Permission';
import { ArticleCategory } from '@/interfaces/models/ArticleCategory';
import { Venue } from '@/interfaces/models/Venue';
import { ValidationObserver } from 'vee-validate';
import { Inventory } from '@/interfaces/models/Inventory';

interface GroupedArticle {
  article: Article;
  type: StockManagementType;
}

const venue = namespace('venue');
const inventory = namespace('inventory');
const foodcard = namespace('foodcard');

@Component({
  components: { VWrapper },
})
export default class EditInventory extends mixins(Editable, Notification) {
  @venue.State('active') public venue!: Venue;

  @inventory.State('active') protected active!: Inventory;
  @inventory.Action('fetch') public getInventory!: any;
  @inventory.Action('setGroupedArticle') protected setGroupedArticle!: any;
  @inventory.Getter('filteredArticles') public filteredArticles!: GroupedArticle[];
  @inventory.Action('setFilter') public setFilter!: any;
  @inventory.Action('store') public store!: any;
  @inventory.Action('updateInventory') public update!: any;
  @foodcard.State('options') protected options!: OptionGroup[];
  @foodcard.State('categories') public categories!: Category[];
  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;
  @foodcard.Action('setOptionFilter') public setOptionFilter!: any;
  @foodcard.Action('fetchOptions') public getOptions!: any;

  public products: string[] = [];

  public stock: number | null = null;
  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  get title() {
    return this.editing ? 'routes.inventory.edit' : 'routes.inventory.create';
  }

  public async mounted() {
    if (!this.venue?._id) {
      await this.$router.push({ name: 'inventory.index' });
      return;
    }

    if (this.editing && this.active) {
      this.stock = this.active?.inventoryItems[0]?.stock ?? 0;
      this.products = this.active?.inventoryItems[0]?.products ?? [];
    }

    try {
      this.$startLoading('inventory');
      this.setOptionFilter({ venue: this.venue._id });
      if (this.$can(Permission.CATEGORY_VIEW)) {
        this.setCategoryFilter({ venue: this.venue._id, articles: true });
        await this.getCategories();
      }
      await this.getOptions();
      this.initData();
    } catch (e) {
      console.log(e);
    } finally {
      this.$stopLoading('inventory');
    }
  }

  public initData() {
    const optionGroupsMap: Map<string | number, OptionGroup> = new Map(this.options.map((group) => [group._id, group]));

    const categories = this.categories.map((category: Category) => ({
      ...category,
      articles: category.articles?.map((article: any) => ({
        ...article,
        groups: article.groups?.map((group: any) => optionGroupsMap.get(group as string) || group),
        categoryName: category.name.de,
      })),
    }));

    const groupedStockManagementGroups: { article: GroupedArticle[]; option: GroupedArticle[] } =
      this.generateGroupedStockManagementGroups(categories);

    this.setGroupedArticle(groupedStockManagementGroups);
  }

  private generateGroupedStockManagementGroups(categories: any[]) {
    const items = this.generateItems(categories);

    return items.reduce((acc, group) => {
      if (!group) return acc;

      const { type } = group;
      acc[type] = acc[type] || [];
      acc[type].push(group);

      return acc;
    }, {} as Record<string, any[]>);
  }

  private generateItems(categories: ArticleCategory[]) {
    const allArticles = this.extractArticles(categories);
    return this.extractStockManagementGroups(categories, allArticles);
  }

  private extractArticles(categories: ArticleCategory[]) {
    return categories
      .flatMap((category) => category.articles?.map((article: any) => ({ ...article })) || [])
      .filter((article, index, self) => index === self.findIndex((a) => a._id === article._id));
  }

  private extractStockManagementGroups(categories: ArticleCategory[], allArticles: any[]) {
    const stockManagementGroups: any[] = [];

    stockManagementGroups.push(
      ...allArticles.map((article) => ({
        type: 'article',
        article,
      })),
    );

    const optionGroups = categories
      .flatMap((category) => (category.articles ?? []).flatMap((article: any) => article.groups))
      .filter((group, index, self) => index === self.findIndex((g) => g._id === group._id));

    stockManagementGroups.push(...this.extractOptions(optionGroups));

    return stockManagementGroups;
  }

  private extractOptions(optionGroups: OptionGroup[]) {
    return optionGroups
      .flatMap(
        (og) =>
          og.articles?.map((article: any) => {
            return {
              article,
              type: 'option',
            };
          }) || [],
      )
      .filter(Boolean);
  }

  public toggleSelection(item: any) {
    const value = this.getItemValue(item);
    const index = this.products.indexOf(value);
    if (index > -1) {
      this.products.splice(index, 1);
    } else {
      this.products = [...this.products, value];
    }
  }

  public getItemValue(item: any) {
    return item?.article?.number || item?.article?.optionNumber;
  }

  public search(value: any) {
    this.setFilter({ search: value, ignoreByNumber: this.products });
  }

  public async save() {
    try {
      this.$startLoading('inventory.save');

      if (!(await this.$refs.observer.validate())) {
        this.notifyError('notification.form');
        return;
      }

      const payload = {
        venue: this.venue._id,
        inventoryItems: [
          {
            products: this.products,
            stock: this.stock,
          },
        ],
      };

      if (this.editing) {
        await this.update({ inventory: payload, venue: this.venue._id, id: this.active._id });
      } else {
        await this.store(payload);
      }

      await this.$router.push({ name: 'inventory.index' });
    } finally {
      this.$stopLoading('inventory.save');
    }
  }
}
