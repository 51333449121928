
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import { Page } from '@/interfaces/api/Page';
import { namespace } from 'vuex-class';
import VWrapper from '@/components/shared/VWrapper.vue';
import { Venue } from '@/interfaces/models/Venue';
import InventoryTable from '@/components/inventory/InventoryTable.vue';
import { Inventory as InventoryModal } from '@/interfaces/models/Inventory';

const venue = namespace('venue');
const inventory = namespace('inventory');

@Component({
  components: { VWrapper, InventoryTable },
})
export default class Inventory extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;
  @inventory.Action('fetch') public getInventory!: any;
  @inventory.Action('setFilter') public setFilter!: any;
  @inventory.Action('destroy') public deleteInventory!: any;
  @inventory.State('items') public items!: any;
  @inventory.State((state) => state.pagination.total) public total!: number;

  public async mounted() {
    if (this.venue?._id) {
      this.$startLoading('inventory');
      this.setFilter({ page: 1, limit: 10 });
      this.getInventory(this.venue?._id);
      this.$stopLoading('inventory');
    }
  }

  public async destroy(inventory: InventoryModal) {
    this.$startLoading('inventory');
    await this.deleteInventory({ id: inventory._id });
    this.$stopLoading('inventory');
  }

  public onPageChange(page: Page) {
    if (page) {
      this.setFilter({ ...page });
      this.getInventory(this.venue?._id);
    }
  }

  @Watch('venue')
  public async onVenueChange() {
    if (!this.venue) return;
    this.$startLoading('inventory');
    this.setFilter({ page: 1, limit: 10 });
    this.getInventory(this.venue?._id);
    this.$stopLoading('inventory');
  }
}
