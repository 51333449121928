export default [
  {
    text: 'inventory.table.stock',
    value: 'stock',
    default: true,
    type: 'slot',
    align: 'left',
    virtual: true,
  },
  {
    text: 'inventory.table.createdAt',
    value: 'createdAt',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'inventory.table.inventoryItems',
    value: 'inventoryItems',
    default: true,
    type: 'slot',
    align: 'left',
  },
  { text: '', value: 'data-table-expand', default: true },
];
